define("ember-date-components/helpers/is-equal-day", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isEqualDay = isEqualDay;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function isEqualDay(_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        d1 = _ref2[0],
        d2 = _ref2[1];

    if (Ember.typeOf(d2) === 'array') {
      return !!Ember.A(d2).find(function (d2) {
        if (!_moment.default.isMoment(d1) || !_moment.default.isMoment(d2)) {
          return false;
        }

        return d1.format('YYYY-MM-DD') === d2.format('YYYY-MM-DD');
      });
    }

    if (!_moment.default.isMoment(d1) || !_moment.default.isMoment(d2)) {
      return false;
    }

    return d1.format('YYYY-MM-DD') === d2.format('YYYY-MM-DD');
  }

  var _default = Ember.Helper.helper(isEqualDay);

  _exports.default = _default;
});