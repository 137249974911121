define('ember-cli-maskedinput/initializers/paste-event', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    var customEvents = application.get('customEvents') || {};
    customEvents.paste = 'paste';
    application.set('customEvents', customEvents);
  }

  exports.default = {
    name: 'paste-event',
    initialize: initialize
  };
});