define("ember-date-components/helpers/date-picker-day-classes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.datePickerDayClasses = datePickerDayClasses;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function datePickerDayClasses(_ref, _ref2) {
    var _ref3 = _slicedToArray(_ref, 1),
        baseClass = _ref3[0];

    var _ref2$isToday = _ref2.isToday,
        isToday = _ref2$isToday === void 0 ? false : _ref2$isToday,
        _ref2$isSelected = _ref2.isSelected,
        isSelected = _ref2$isSelected === void 0 ? false : _ref2$isSelected,
        _ref2$isDisabled = _ref2.isDisabled,
        isDisabled = _ref2$isDisabled === void 0 ? false : _ref2$isDisabled,
        _ref2$isInRange = _ref2.isInRange,
        isInRange = _ref2$isInRange === void 0 ? false : _ref2$isInRange;
    var isTodayClass = isToday ? " ".concat(baseClass, "--today") : '';
    var isSelectedClass = isSelected ? " ".concat(baseClass, "--selected") : '';
    var isDisabledClass = isDisabled ? " ".concat(baseClass, "--disabled") : '';
    var isInRangeClass = isInRange ? " ".concat(baseClass, "--in-range") : '';
    return "".concat(baseClass).concat(isTodayClass).concat(isSelectedClass).concat(isDisabledClass).concat(isInRangeClass);
  }

  var _default = Ember.Helper.helper(datePickerDayClasses);

  _exports.default = _default;
});